<header>
  <a routerLink="/main">
    <img id="logo" src="assets/logo.png">
  </a>

  <div class="link">
    <a routerLink="/algebra">Algebra</a>
  </div>

  <div class="link">
    <a routerLink="/shell">Shell</a>
  </div>

  <!-- <div class="link">
    <a routerLink="/math-collabo">MathCollabo</a>
  </div> -->
</header>

<div id="description">
  MathFreeOn is an online math software platform
</div>

<div id="content-area">
  <router-outlet></router-outlet>
</div>

<footer>
  Copyright 2021. CEMWARE, Inc. All rights reserved.
</footer>

<div id="container">
  <div id="tree">
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
    >
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        matTreeNodePaddingIndent="16"
        (click)="onNodeClick(node)"
      >
        <button mat-icon-button disabled></button>
        {{node.name}}
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        (click)="onNodeClick(node)"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </mat-tree-node>
    </mat-tree>
  </div>
  
  <div id="view">
    <p id="mfo-a:1">
      Math3D is an online function graphing and calculation application.
      It fully supports 3D implicit plotting of multivariate functions.
      It also has some programming features which are explained in the <b>"Shell"</b> part.
    </p>

    <div class="image-wrapper">
      <img src="assets/algebra/introduction.png">
    </div>

    <div class="spacer"></div>

    <div class="image-wrapper">
      <img src="assets/algebra/ui.png">
    </div>

    <div class="spacer"></div>
    <div class="spacer"></div>

    <p id="mfo-a:2">
      In Math3D, curretly 7 types of expressions are supported.
      More advanced features such as differentiation and integral
      will be supported in the near future.
    </p>
    <div id="mfo-a:3">
      <div>(1) Function Definition</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="f\left(x,\ y\right)=x^2+\sin \left(y\right)"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expressions consists of a function head,
        an equality sign, and a body.
      </div>
      <div>
        The name of a function can have a subscript.
      </div>
      <div>
        This type of items can have a domain definition.
      </div>

      <div class="spacer"></div>

      <div id="mfo-a:4">(2) Direct Parameter Definition</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="a=1"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expressions consists of a one-letter parameter name,
        an equality sign, and a numeric string.
      </div>
      <div>
        The name of a parameter can have a subscript.
      </div>
      <div>
        This type of items is represented as a slider item in the "Algebra" panel.
      </div>
      <div>
         A direct parameter cannot have a domain definition.
      </div>

      <div class="spacer"></div>

      <div id="mfo-a:5">(3) Indirect Parameter Definition</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="a=1"
        ></mfo-math-quill>
      </div>
      <div class="expression highlight">
        <mfo-math-quill
          editType="static"
          initialExpression="b=a+\pi"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expression consists of a one-letter parameter name,
        an equality sign,
        and an expression which can be evaluated immediately as a number.
      </div>
      <div>
        The name of an indirect parameter can have a subscript.
      </div>
      <div>
        This type of items cannot have a domain definition.
      </div>

      <div class="spacer"></div>

      <div id="mfo-a:6">(4) Functional Equation</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="y=x^2"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expressions consists of a variable name, an equality sign, and a body.
      </div>
      <div>
        The body part should not contain the left hand side variable.
      </div>
      <div>
        This type of items can have a domain definition.
      </div>

      <div class="spacer"></div>
      
      <div id="mfo-a:7">(5) Generic Equation</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="y+z=x^2+z^3"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expressions consists of a left hand side expression,
        an equality sign, and a right hand side expression.
      </div>
      <div>
        Generic equations can have domain definitions.
      </div>

      <div class="spacer"></div>

      <div id="mfo-a:8">(6) Functional Expression</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="y+z+x^2+z^3"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expressions has no equality signs.
      </div>
      <div>
        A functional expression can have a domain definition.
      </div>

      <div class="spacer"></div>
      
      <div id="mfo-a:9">(7) Immediate Expression</div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="a=1"
        ></mfo-math-quill>
      </div>
      <div class="expression">
        <mfo-math-quill
          editType="static"
          initialExpression="f\left(x,\ y,\ z\right)=x+y^2+z^3"
        ></mfo-math-quill>
      </div>
      <div class="expression highlight">
        <mfo-math-quill
          editType="static"
          initialExpression="1+f\left(1,\ 2,\ 3\right)+a"
        ></mfo-math-quill>
      </div>
      <div>
        This type of expressions has no equality signs and
        can be evaluated as a number immediately.
      </div>
      <div>
        An immediate expression cannot have a domain definition.
      </div>
    </div>

    <div class="spacer"></div>

    <p id="mfo-a:10">
      Elementary functions.
    </p>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\cos \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\sin \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\tan \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\arccos \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\arcsin \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\arctan \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\sec \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\csc \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\cot \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\cosh \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\sinh \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\tanh \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\ln \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\log \left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="expression">
      <mfo-math-quill
        editType="static"
        initialExpression="\log _n\left(x\right)"
      ></mfo-math-quill>
    </div>

    <div class="spacer"></div>
    
    <div id="mfo-a:11">
      Currently, two types of objects - <b>folders</b> and <b>expressions</b> - are supported.
    </div>

    <p id="mfo-a:12">
      Adding an expression can be done in two ways.
      Firstly, you can use the "Add Expression" button.
    </p>
    <div class="image-wrapper">
      <img src="assets/algebra/adding-expression-1.png">
    </div>
    <p>
      The second method is to use "Enter" key in the last focused item.
    </p>
    <div class="image-wrapper">
      <img src="assets/algebra/adding-expression-2.png">
    </div>

    <div class="spacer"></div>

    <p id="mfo-a:13">
      To help organize expressions, Math3D provides a one-level grouping feature.
      Use the "Add Folder" button.
    </p>
    <div class="image-wrapper">
      <img src="assets/algebra/adding-group.png">
    </div>

    <p id="mfo-a:14">
      You can use drag-and-drop to move around items. This allows you to change the order of items and their group membership.
    </p>

    <div class="spacer"></div>

    <div id="mfo-a:15">
      Examples. You can copy and paste the expressions to Math3D algebra panel
      to see the actual results.
    </div>

    <div class="spacer"></div>

    <div>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Function Definition
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="f\left(x,\ y,\ z\right)=\sin \left(x\right)+\frac{y}{z}"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-1.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Direct Parameter Definition
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="a=1"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-2.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Indirect Parameter Definition
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="a=1"
            ></mfo-math-quill>
          </div>

          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="b=a+1"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-3.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Functional Equation
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="z=\sin \left(x\right)+\cos \left(y\right)y^2"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-4.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Generic Equation
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="z+y^{\frac{1}{3}}=\sin \left(x\right)y+2^yy^2"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-5.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Functional Expression
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="z-\sin \left(x\right)\cos \left(y\right)-z^2"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-6.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Immediate Expression
            </mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="expression">
            <mfo-math-quill
              editType="static"
              initialExpression="1+\sqrt{102}+\sin \left(10\right)"
            ></mfo-math-quill>
          </div>

          <div class="spacer"></div>

          <div class="image-wrapper">
            <img src="assets/algebra/example-7.png">
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="spacer"></div>
  </div>
</div>

<div id="container">
  <div id="tree">
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
    >
      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        matTreeNodePaddingIndent="16"
        (click)="onNodeClick(node)"
      >
        <button mat-icon-button disabled></button>
        {{node.name}}
      </mat-tree-node>

      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        (click)="onNodeClick(node)"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </mat-tree-node>
    </mat-tree>
  </div>

  <div id="view">
    <p id="mfo-s:1">
      Shell is a JavaScript-based programming interface
      to the Math3D system.
    </p>

    <div class="image-wrapper">
      <img src="assets/shell/ui.png">
    </div>

    <div class="spacer"></div>

    <p id="mfo-s:2">API Reference</p>

    <p id="mfo-s:3">
      (1) Math Constants and Functions.
      Here, the Vector3 type is a JavaScript object &#123; x: number, y: number, z: number &#125;.
    </p>
    <div class="api-item"><b>Math.E</b> : Euler's constant</div>
    <div class="api-item"><b>Math.LN2</b> : Natural logarithm of 2 (ln2)</div>
    <div class="api-item"><b>Math.LN10</b> : Natural logarithm of 10 (ln10)</div>
    <div class="api-item"><b>Math.LOG2E</b> : log<sub>2</sub>e</div>
    <div class="api-item"><b>Math.LOG10E</b> : log<sub>10</sub>e</div>
    <div class="api-item"><b>Math.PI</b> : Archimedes' constant</div>
    <div class="api-item"><b>Math.SQRT1_2</b> : Square root of a half (1/2)</div>
    <div class="api-item"><b>Math.SQRT2</b> : Square root of 2</div>
    <div class="api-item"><b>Math.abs(n: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.acos(x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.asin(x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.atan(x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.atan2(y: <span class="type">number</span>, x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.cos(θ: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.sin(θ: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.tan(θ: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.ceil(n: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.floor(n: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.round(n: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.exp(x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.pow(base: <span class="type">number</span>, exponent: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.log(x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.sqrt(x: <span class="type">number</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.min(values: <span class="type">number[]</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.max(values: <span class="type">number[]</span>): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.random(): <span class="type">number</span></b></div>
    <div class="api-item"><b>Math.normalize(x: <span class="type">Vector3</span>): <span class="type">Vector3</span></b></div>
    <div class="api-item"><b>Math.cross(x: <span class="type">Vector3</span>, y: <span class="type">Vector3</span>): <span class="type">Vector3</span></b></div>
    <div class="api-item"><b>Math.dot(x: <span class="type">Vector3</span>, y: <span class="type">Vector3</span>): <span class="type">number</span></b></div>
    
    <p id="mfo-s:4">(2) Cem Constants and Functions</p>
    <div class="api-item">
      <div><b>Cem.setMachineSpeed(speed: <span class="type">number</span>): <span class="type">void</span></b></div>
      <div>- Specifies how fast the shell virtual machine executes.</div>
      <div>- Range: 1 ~ 10000</div>
      <div>- Default: 300</div>
    </div>
    <div class="api-item">
      <div><b>Cem.now(): <span class="type">number</span></b> : Returns the current application time.</div>
    </div>
    <div class="api-item">
      <div><b>Cem.pause(message: <span class="type">string</span>): <span class="type">void</span></b></div>
      <div>- Pauses the execution at the called position.</div>
      <div>- The passed message will be printed in the output console.</div>
      <div>- The message should be of string type.</div>
    </div>
    <div class="api-item">
      <div><b>Cem.print(message: <span class="type">string</span>): <span class="type">void</span></b></div>
      <div>- Prints the passed messge in the output console.</div>
      <div>- The message should be of string type.</div>
    </div>

    <p id="mfo-s:5">(3) Algebra Constants and Functions</p>
    <div class="api-item">
      <div><b>Algebra.setParameterValue(name: <span class="type">string</span>, value: <span class="type">number</span>): <span class="type">void</span></b></div>
      <div>- Sets the specified direct parameter's value.</div>
      <div>- To encode subscripted name, use underscore. Ex.) "a_1".</div>
    </div>
    <div class="api-item">
      <div><b>Algebra.getParameterValue(name: <span class="type">string</span>): <span class="type">number</span></b></div>
      <div>- Gets the specified direct parameter's value.</div>
      <div>- To encode subscripted name, use underscore. Ex.) "a_1".</div>
    </div>
    <div class="api-item">
      <div><b>Algebra.evaluateFunction(name: <span class="type">string</span>, x: <span class="type">number</span>, y: <span class="type">number</span>, z: <span class="type">number</span>): <span class="type">number</span></b></div>
      <div>- Gets the specified functions's value at the specified point.</div>
      <div>- To encode subscripted name, use underscore. Ex.) "f_1".</div>
    </div>

    <p id="mfo-s:6">(4) Graphics Constants and Functions</p>
    <div class="api-item">
      <b>Graphics.GEOM_LINE</b> : See
      <span class="link" (click)="onLinkClick(10)">(8) Canvas Graphics</span>
    </div>
    <div class="api-item">
      <b>Graphics.GEOM_CIRCLE</b> : See
      <span class="link" (click)="onLinkClick(10)">(8) Canvas Graphics</span>
    </div>
    <div class="api-item">
      <b>Graphics.GEOM_PATH</b> : See
      <span class="link" (click)="onLinkClick(10)">(8) Canvas Graphics</span>
    </div>
    <div class="api-item">
      <b>Graphics.getPen(): <span class="type">Pen</span></b> : See
      <span class="link" (click)="onLinkClick(7)">(5) Pen Graphics</span>
    </div>
    <div class="api-item">
      <b>Graphics.getTurtle(): <span class="type">Turtle</span></b> : See
      <span class="link" (click)="onLinkClick(8)">(6) Turtle Graphics</span>
    </div>
    <div class="api-item">
      <b>Graphics.getUtility(): <span class="type">Utility</span></b> : See
      <span class="link" (click)="onLinkClick(9)">(7) Utility Graphics</span>
    </div>
    <div class="api-item">
      <b>Graphics.getCanvas(): <span class="type">Canvas</span></b> : See
      <span class="link" (click)="onLinkClick(10)">(8) Canvas Graphics</span>
    </div>

    <p id="mfo-s:7">
      (5) Pen Graphics
    </p>
    <div class="api-item">
      <div>Use <b>Graphics.getPen()</b> to get the system pen object.</div>
      <div>Ex.) <span class="var">var</span> thePen = Graphics.getPen();</div>
      <pre class="code">
<span class="interface">interface</span> <span class="type">Point3</span> {{ '{' }}
  x: <span class="type">number</span>,
  y: <span class="type">number</span>,
  z: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Color3</span> {{ '{' }}
  r: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  g: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  b: <span class="type">number</span>  <span class="comment">// 0 ~ 255</span>
{{ '}' }}

<span class="interface">interface</span> <b><span class="type">Pen</span></b> {{ '{' }}
  clear(): <span class="type">void</span>; <span class="comment">// clears pen drawings</span>
  
  show(): <span class="type">void</span>; <span class="comment">// shows the pen</span>
  hide(): <span class="type">void</span>; <span class="comment">// hides the pen</span>
  
  newSession(startPosition: <span class="type">Point3</span>, color: <span class="type">Color3</span>): <span class="type">void</span>;
  drawTo(position: <span class="type">Point3</span>): <span class="type">void</span>;
{{ '}' }}</pre>
    </div>

    <p id="mfo-s:8">
      (6) Turtle Graphics
    </p>
    <div class="api-item">
      <div>Use <b>Graphics.getTurtle()</b> to get the system turtle object.</div>
      <div>Ex.) <span class="var">var</span> theTurtle = Graphics.getTurtle();</div>
      <pre class="code">
<span class="interface">interface</span> <span class="type">Vector3</span> {{ '{' }}
  x: <span class="type">number</span>,
  y: <span class="type">number</span>,
  z: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Point3</span> {{ '{' }}
  x: <span class="type">number</span>,
  y: <span class="type">number</span>,
  z: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <b><span class="type">Turtle</span></b> {{ '{' }}
  clear(): <span class="type">void</span>; <span class="comment">// clears any trailings</span>
  
  show(): <span class="type">void</span>; <span class="comment">// shows the turtle</span>
  hide(): <span class="type">void</span>; <span class="comment">// hides the turtle</span>
  
  beginTrailing(): <span class="type">void</span>;
  endTrailing(): <span class="type">void</span>;
  
  setPosition(position: <span class="type">Point3</span>): <span class="type">void</span>;

  <span class="comment">// The vector inputs should be ortho-normal to each other.</span>
  setAxes(front: <span class="type">Vector3</span>, up: <span class="type">Vector3</span>): <span class="type">void</span>;
  setFrame(position: <span class="type">Point3</span>, front: <span class="type">Vector3</span>, up: <span class="type">Vector3</span>): <span class="type">void</span>;
{{ '}' }}</pre>
    </div>

    <p id="mfo-s:9">
      (7) Utility Graphics
    </p>
    <div class="api-item">
      <div>Use <b>Graphics.getUtility()</b> to get the system utility object.</div>
      <div>Ex.) <span class="var">var</span> theUtility = Graphics.getUtility();</div>
      <pre class="code">
<span class="interface">interface</span> <span class="type">Vector3</span> {{ '{' }}
  x: <span class="type">number</span>,
  y: <span class="type">number</span>,
  z: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Point3</span> {{ '{' }}
  x: <span class="type">number</span>,
  y: <span class="type">number</span>,
  z: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Color4</span> {{ '{' }}
  r: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  g: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  b: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  a: <span class="type">number</span>  <span class="comment">// 0 ~ 1</span>
{{ '}' }}

<span class="interface">interface</span> <b><span class="type">Utility</span></b> {{ '{' }}
  clear(): <span class="type">void</span>; <span class="comment">// clears all</span>

  clearSphereMarkers(): <span class="type">void</span>;
  createSphereMarkers(count: <span class="type">number</span>): <span class="type">void</span>;
  configSphereMarker(index: <span class="type">number</span>, center: <span class="type">Point3</span>, color: <span class="type">Color4</span>): <span class="type">void</span>;

  clearBoxMarkers(): <span class="type">void</span>;
  createBoxMarkers(count: number): <span class="type">void</span>;
  configBoxMarker(index: <span class="type">number</span>, center: <span class="type">Point3</span>, color: <span class="type">Color4)</span>: <span class="type">void</span>;

  clearVectors(): <span class="type">void</span>;
  createVectors(count: <span class="type">number</span>): <span class="type">void</span>;
  configVector(index: <span class="type">number</span>, anchor: <span class="type">Point3</span>, vec: <span class="type">Vector3</span>, color: <span class="type">Color4</span>): <span class="type">void</span>;
{{ '}' }}</pre>
    </div>

    <p id="mfo-s:10">
      (8) Canvas Graphics
    </p>
    <div class="api-item">
      <div>Use <b>Graphics.getCanvas()</b> to get the system canvas object.</div>
      <div>Ex.) <span class="var">var</span> theCanvas = Graphics.getCanvas();</div>
      <pre class="code">
<span class="interface">interface</span> <span class="type">Point2</span> {{ '{' }}
  x: <span class="type">number</span>,
  y: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Color4</span> {{ '{' }}
  r: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  g: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  b: <span class="type">number</span>, <span class="comment">// 0 ~ 255</span>
  a: <span class="type">number</span>  <span class="comment">// 0 ~ 1</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Line</span> {{ '{' }}
  type: <span class="type">number</span>, <span class="comment">// Cem.GEOM_LINE</span>
  start: <span class="type">Point2</span>,
  end: <span class="type">Point2</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Circle</span> {{ '{' }}
  type: <span class="type">number</span>, <span class="comment">// Cem.GEOM_CIRCLE</span>
  center: <span class="type">Point2</span>,
  radius: <span class="type">number</span>
{{ '}' }}

<span class="interface">interface</span> <span class="type">Path</span> {{ '{' }}
  type: <span class="type">number</span>, <span class="comment">// Cem.GEOM_PATH</span>
  points: <span class="type">Point2[]</span> <span class="comment">// path points</span>
{{ '}' }}

<span class="interface">interface</span> <b><span class="type">Canvas</span></b> {{ '{' }}
  clear(): <span class="type">void</span>; <span class="comment">// clears all</span>

  draw(
    geometry: <span class="type">Line | Circle | Path</span>,
    color: <span class="type">Color4</span>
  ): <span class="type">void</span>;

  fill(
    geometry: <span class="type">Line | Circle | Path</span>,
    color: <span class="type">Color4</span>
  ): <span class="type">void</span>;
  
  fillComposite(
    geometries: <span class="type">Line | Circle | Path[]</span>,
    color: <span class="type">Color4</span>,
    regionSelector: <span class="type">Point2</span>
  ): <span class="type">void</span>;
{{ '}' }}</pre>
    </div>
    
    <div class="spacer"></div>

    <p id="mfo-s:11">
      Examples. You can copy and paste the code to Math3D shell panel to see the actual results.
    </p>

    <div id="examples">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Pen Graphics</mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="example">
            <div class="header">
              <div class="title">Code</div>
              <button
                mat-icon-button
                disabled="false"
                color="primary"
                matTooltip="Copy"
                (click)="onCopy(1)"
              >
                <mat-icon svgIcon="shell:copy"></mat-icon>
              </button>
            </div>
            <div class="editor-wrapper">
              <mfo-monaco-editor
                #editor1
                id="editor1"
                class="editor"
                [options]="editorOptions"
              ></mfo-monaco-editor>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Turtle Graphics</mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="example">
            <div class="header">
              <div class="title">Code</div>
              <button
                mat-icon-button
                disabled="false"
                color="primary"
                matTooltip="Copy"
                (click)="onCopy(2)"
              >
                <mat-icon svgIcon="shell:copy"></mat-icon>
              </button>
            </div>
            <div class="editor-wrapper">
              <mfo-monaco-editor
                #editor2
                id="editor2"
                class="editor"
                [options]="editorOptions"
              ></mfo-monaco-editor>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>
      
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Utility Graphics</mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="example">
            <div class="header">
              <div class="title">Code</div>
              <button
                mat-icon-button
                disabled="false"
                color="primary"
                matTooltip="Copy"
                (click)="onCopy(3)"
              >
                <mat-icon svgIcon="shell:copy"></mat-icon>
              </button>
            </div>
            <div class="editor-wrapper">
              <mfo-monaco-editor
                #editor3
                id="editor3"
                class="editor"
                [options]="editorOptions"
              ></mfo-monaco-editor>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>
      
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Canvas Graphics</mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="example">
            <div class="header">
              <div class="title">Code</div>
              <button
                mat-icon-button
                disabled="false"
                color="primary"
                matTooltip="Copy"
                (click)="onCopy(4)"
              >
                <mat-icon svgIcon="shell:copy"></mat-icon>
              </button>
            </div>
            <div class="editor-wrapper">
              <mfo-monaco-editor
                #editor4
                id="editor4"
                class="editor"
                [options]="editorOptions"
              ></mfo-monaco-editor>
            </div>
          </div>        
        </mat-expansion-panel>
      </mat-accordion>

      <div class="spacer"></div>
      
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Integrated</mat-panel-title>
          </mat-expansion-panel-header>
          
          <div class="example">
            <div class="header">
              <div class="title">Code</div>
              <button
                mat-icon-button
                disabled="false"
                color="primary"
                matTooltip="Copy"
                (click)="onCopy(5)"
              >
                <mat-icon svgIcon="shell:copy"></mat-icon>
              </button>
            </div>
            <div class="editor-wrapper">
              <mfo-monaco-editor
                #editor5
                id="editor5"
                class="editor"
                [options]="editorOptions"
              ></mfo-monaco-editor>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="spacer"></div>
  </div>
</div>

<div class="root">
  <!-- <img id="image" src="assets/main.png"> -->
  <div class="section">
    <img src="assets/main.png">
    <div class="button-group">
      <div class="button" (click)="onOpenMath2DClick()">Open Math2D</div>
      <div class="button" (click)="onOpenMath3DClick()">Open Math3D</div>
      <div class="button" (click)="onOpenSimtoolClick()">Open SimTool</div>
      <div class="button" (click)="onOpenGeniCubeClick()">Open GeniCube</div>
      <div class="button" (click)="onOpenGeniAiClick()">Open GeniAI</div>
      <div class="button" (click)="onOpenGeniMathClick()">Open GeniMath</div>
    </div>
  </div>
<!--
  <div class="section">
    <img src="assets/ai-studio.png">
    <div class="button" (click)="onOpenAiStudioClick()">Open AI Studio</div>
  </div> -->
</div>

import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Output } from '@angular/core';

declare let MathQuill: any;

@Component({
  selector: 'mfo-math-quill',
  templateUrl: './math-quill.component.html',
  styleUrls: ['./math-quill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MathQuillComponent implements AfterViewInit {
  // Inputs ---------------------------------------------------------------------------------------
  @Input() public editType: string = 'dynamic'; // 'dynamic' or 'static'
  @Input() public initialExpression: string = '';
  // ----------------------------------------------------------------------------------------------

  // Events ---------------------------------------------------------------------------------------
  @Output() public contentChangeEvent: EventEmitter<any> = new EventEmitter(false);
  @Output() public enterEvent: EventEmitter<any> = new EventEmitter(false);
  @Output() public focusEvent: EventEmitter<any> = new EventEmitter(false);
  @Output() public blurEvent: EventEmitter<any> = new EventEmitter(false);
  // ----------------------------------------------------------------------------------------------

  public mathField: any = null;

  public constructor() { }

  public ngAfterViewInit(): void {
    let mq = MathQuill.getInterface(2);
    if (this.editType === 'dynamic') {
      this.mathField = mq.MathField(this.mqHost.nativeElement, {
        handlers: { edit: () => this.contentChangeEvent.emit() }    
      });

      this.mqHost.nativeElement.addEventListener('focus', () => {
        this.focusEvent.emit();
      }, true);

      this.mqHost.nativeElement.addEventListener('blur', () => {
        this.blurEvent.emit();
      }, true);

      this.mqHost.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          this.enterEvent.emit();
        }
      });
    } else {
      this.mathField = mq.StaticMath(this.mqHost.nativeElement);
    }

    this.mathField.latex(this.initialExpression);
  }

  public setTextCursor(): void {
    this.mqHost.nativeElement.style.cursor = 'text';
  }

  public setArrowCursor(): void {
    this.mqHost.nativeElement.style.cursor = 'default';
  }

  @ViewChild('mqHost')
  private mqHost: ElementRef<HTMLElement> = null;
}

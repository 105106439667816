import { NgModule } from '@angular/core';

import './implementation/mathquill';
import { MathQuillComponent } from './math-quill.component';

@NgModule({
  imports: [],
  declarations: [
    MathQuillComponent
  ],
  exports: [
    MathQuillComponent
  ]
})
export class MathQuillModule { }

import { Component } from '@angular/core';
import Swal from 'sweetalert2';

import { environment } from '../environments/environment';

@Component({
  selector: 'mfo-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  public onOpenMath2DClick(): void {
    if (this.math2dWindow) {
      if (this.math2dWindow.closed) {
        this.math2dWindow = window.open(environment.MATH2D_URL, 'Cemware.MathFreeOn.Math2D');
      } else {
        this.math2dWindow.focus();
      }
    } else {
      this.math2dWindow = window.open(environment.MATH2D_URL, 'Cemware.MathFreeOn.Math2D');
    }
  }

  public onOpenMath3DClick(): void {
    if (this.math3dWindow) {
      if (this.math3dWindow.closed) {
        this.math3dWindow = window.open(environment.MATH3D_URL, 'Cemware.MathFreeOn.Math3D');
      } else {
        this.math3dWindow.focus();
      }
    } else {
      this.math3dWindow = window.open(environment.MATH3D_URL, 'Cemware.MathFreeOn.Math3D');
    }
  }


  public onOpenSimtoolClick(): void {
    if (this.simtoolWindow) {
      if (this.simtoolWindow.closed) {
        this.simtoolWindow = window.open(environment.SIMTOOL_URL, 'Cemware.MathFreeOn.SimTool');
      } else {
        this.simtoolWindow.focus();
      }
    } else {
      this.simtoolWindow = window.open(environment.SIMTOOL_URL, 'Cemware.MathFreeOn.SimTool');
    }
  }

  public onOpenGeniAiClick(): void {
    if (this.geniaiWindow) {
      if (this.geniaiWindow.closed) {
        this.geniaiWindow = window.open(environment.GENIAI_URL, 'Cemware.MathFreeOn.GeniAi');
      } else {
        this.geniaiWindow.focus();
      }
    } else {
      this.geniaiWindow = window.open(environment.GENIAI_URL, 'Cemware.MathFreeOn.GeniAi');
    }
  }


  public onOpenGeniCubeClick(): void {
    if (true) {
      // WHILE DEVELOPMENT
      Swal.fire({
        title: 'GeniCube is preparing...',
        heightAuto: false,
      });
      return;
    }

    if (this.genicubeWindow) {
      if (this.genicubeWindow.closed) {
        this.genicubeWindow = window.open(environment.GENICUBE_URL, 'Cemware.MathFreeOn.GeniCube');
      } else {
        this.genicubeWindow.focus();
      }
    } else {
      this.genicubeWindow = window.open(environment.GENICUBE_URL, 'Cemware.MathFreeOn.GeniCube');
    }
  }

  public onOpenGeniMathClick(): void {
    if (this.genimathWindow) {
      if (this.genimathWindow.closed) {
        this.genimathWindow = window.open(environment.GENIMATH_URL, 'Cemware.MathFreeOn.GeniMath');
      } else {
        this.genimathWindow.focus();
      }
    } else {
      this.genimathWindow = window.open(environment.GENIMATH_URL, 'Cemware.MathFreeOn.GeniMath');
    }
  }

  public onOpenAiStudioClick(): void {
    if (this.aiStudioWindow) {
      if (this.aiStudioWindow.closed) {
        this.aiStudioWindow = window.open(environment.AI_STUDIO_URL, 'Cemware.MathFreeOn.AI Studio');
      } else {
        this.aiStudioWindow.focus();
      }
    } else {
      this.aiStudioWindow = window.open(environment.AI_STUDIO_URL, 'Cemware.MathFreeOn.AI Studio');
    }
  }

  private math2dWindow: Window = null;
  private math3dWindow: Window = null;
  private genicubeWindow: Window = null;
  private genimathWindow: Window = null;
  private aiStudioWindow: Window = null;
  private simtoolWindow: Window = null;
  private geniaiWindow: Window = null;
}

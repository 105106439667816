import { Component } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'mfo-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public ngAfterViewInit(): void {
    // setTimeout(() => {
    //   window.open(environment.MATH3D_URL, 'MathFreeOn.Math3D');
    // }, 500);
  }
}

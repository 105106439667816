import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { OnChanges } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { SimpleChanges } from '@angular/core';

import { filter, take } from 'rxjs/operators';
import { editor } from 'monaco-editor';

import { MonacoEditorLoaderService } from './monaco-editor-loader.service';

declare const monaco: any;

@Component({
  selector: 'mfo-monaco-diff-editor',
  templateUrl: './monaco-diff-editor.component.html',
  styleUrls: ['./monaco-diff-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonacoDiffEditorComponent implements OnInit, OnChanges, OnDestroy {
  container: HTMLDivElement;
  editor: editor.IStandaloneDiffEditor;

  @Input() original: string;
  @Input() modified: string;
  @Input() options: editor.IDiffEditorConstructionOptions;

  @ViewChild('diffEditor', { static: true }) editorContent: ElementRef;

  constructor(private monacoLoader: MonacoEditorLoaderService) { }

  ngOnInit() {
    this.container = this.editorContent.nativeElement;
    this.monacoLoader.isMonacoLoaded.pipe(
      filter(isLoaded => isLoaded),
      take(1),
    ).subscribe(() => {
      this.initMonaco();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editor && ((changes.code && !changes.code.firstChange) || (changes.modified && !changes.modified.firstChange))) {
      const modified = monaco.editor.createModel(this.modified);
      const original = monaco.editor.createModel(this.original);
      this.editor.setModel({
        original,
        modified
      });
    }
    if (
      this.editor &&
      changes.options &&
      !changes.options.firstChange
    ) {
      if (changes.options.previousValue.theme !== changes.options.currentValue.theme) {
        monaco.editor.setTheme(changes.options.currentValue.theme);
      }

      if (changes.options.previousValue.readOnly !== changes.options.currentValue.readOnly) {
        this.editor.updateOptions({ readOnly: changes.options.currentValue.readOnly })
      }
    }
  }

  private initMonaco() {
    let opts: editor.IDiffEditorConstructionOptions = {
      readOnly: true,
      theme: 'vc'
    };
    if (this.options) {
      opts = Object.assign({}, opts, this.options);
    }
    this.editor = monaco.editor.createDiffEditor(this.container, opts);

    const original = monaco.editor.createModel(this.original);
    const modified = monaco.editor.createModel(this.modified);

    this.editor.setModel({
      original,
      modified
    });
    this.editor.layout();
  }

  onResized(event) {
    if (this.editor) {
      this.editor.layout({
        width: event.newWidth,
        height: event.newHeight
      });
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.dispose();
    }
  }
}

import { Component } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { MatTreeFlattener} from '@angular/material/tree';

interface ContentTableDataNode {
  id: number;
  name: string;
  children?: ContentTableDataNode[];
}

const CONTENT_TABLE_DATA: ContentTableDataNode[] = [
  {
    id: 1,
    name: 'Introduction'
  },
  {
    id: 2,
    name: 'Types of Expressions',
    children: [
      {
        id: 3,
        name: '(1) Function Definition'
      },
      {
        id: 4,
        name: '(2) Direct Parameter Definition'
      },
      {
        id: 5,
        name: '(3) Indirect Parameter Definition'
      },
      {
        id: 6,
        name: '(4) Functional Equation'
      },
      {
        id: 7,
        name: '(5) Generic Equation'
      },
      {
        id: 8,
        name: '(6) Functional Expression'
      },
      {
        id: 9,
        name: '(7) Immediate Expression'
      }
    ]
  },
  {
    id: 10,
    name: 'Elementary Functions'
  },
  {
    id: 11,
    name: 'Object Manipulation',
    children: [
      {
        id: 12,
        name: 'Adding an expression'
      },
      {
        id: 13,
        name: 'Adding a folder'
      },
      {
        id: 14,
        name: 'Reordering'
      }
    ]
  },
  {
    id: 15,
    name: 'Examples'
  }
];

interface ContentTableNode {
  id: number;
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'mfo-algebra',
  templateUrl: './algebra.component.html',
  styleUrls: ['./algebra.component.scss']
})
export class AlgebraComponent implements AfterViewInit {
  private transformer = (node: ContentTableDataNode, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  public treeControl = new FlatTreeControl<ContentTableNode>(
    node => node.level, node => node.expandable
  );

  private treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  public hasChild = (_: number, node: ContentTableNode) => node.expandable;

  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public constructor() {
    this.dataSource.data = CONTENT_TABLE_DATA;
  }

  public ngAfterViewInit(): void {
    this.treeControl.expandAll();
  }

  public onNodeClick(node: ContentTableNode): void {
    let id = 'mfo-a:' + node.id;
    document.getElementById(id).scrollIntoView();
  }
}

import { filter, take } from 'rxjs/operators';

import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { OnChanges } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { forwardRef } from '@angular/core';
import { SimpleChanges } from '@angular/core';

import { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { Validator } from '@angular/forms';
import { ValidationErrors } from '@angular/forms';

import { editor } from 'monaco-editor';

import { ResizedDirective } from './resized-event.directive';
import { MonacoEditorLoaderService } from './monaco-editor-loader.service';

declare const monaco: any;

@Component({
  selector: 'mfo-monaco-editor',
  templateUrl: './monaco-editor.component.html',
  styleUrls: ['./monaco-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonacoEditorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MonacoEditorComponent),
      multi: true,
    }
  ]
})
export class MonacoEditorComponent implements
  OnInit,
  OnChanges,
  OnDestroy,
  ControlValueAccessor,
  Validator
/**/ {
  @Input() options: editor.IStandaloneEditorConstructionOptions;
  @ViewChild('editor', { static: true }) editorContent: ElementRef;
  @ViewChild('resizeDirective', { static: true }) resizeDirective: ResizedDirective;

  container: HTMLDivElement;
  editor: editor.IStandaloneCodeEditor;
  value: string;
  parseError: boolean;

  private onTouched: () => void;
  private onErrorStatusChange: () => void;
  private propagateChange: (_: any) => any = (_: any) => { };

  constructor(private monacoLoader: MonacoEditorLoaderService) { }

  ngOnInit() {
    this.container = this.editorContent.nativeElement;
    this.monacoLoader.isMonacoLoaded.pipe(
      filter(isLoaded => isLoaded),
      take(1)
    ).subscribe(() => {
      this.initMonaco();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editor && changes.options && !changes.options.firstChange) {
      if (changes.options.previousValue.language !== changes.options.currentValue.language) {
        monaco.editor.setModelLanguage(
          this.editor.getModel(),
          this.options && this.options.language ? this.options.language : 'text'
        );
      }
      if (changes.options.previousValue.theme !== changes.options.currentValue.theme) {
        monaco.editor.setTheme(changes.options.currentValue.theme);
      }
      if (changes.options.previousValue.readOnly !== changes.options.currentValue.readOnly) {
        this.editor.updateOptions({ readOnly: changes.options.currentValue.readOnly });
      }
    }
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.editor && value) {
      this.editor.setValue(value);
    } else if (this.editor) {
      this.editor.setValue('');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors {
    return (!this.parseError) ? null : {
      parseError: {
        valid: false,
      }
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onErrorStatusChange = fn;
  }

  private initMonaco() {
    let opts: editor.IStandaloneEditorConstructionOptions = {
      value: [this.value].join('\n'),
      language: 'text',
      automaticLayout: true,
      scrollBeyondLastLine: false,
      theme: 'vc'
    };
    if (this.options) {
      opts = Object.assign({}, opts, this.options);
    }
    this.editor = monaco.editor.create(this.container, opts);
    this.editor.layout();

    this.editor.onDidChangeModelContent(() => {
      this.propagateChange(this.editor.getValue());
    });

    this.editor.onDidChangeModelDecorations(() => {
      const pastParseError = this.parseError;
      if (monaco.editor.getModelMarkers({}).map(m => m.message).join(', ')) {
        this.parseError = true;
      } else {
        this.parseError = false;
      }

      if (pastParseError !== this.parseError) {
        this.onErrorStatusChange();
      }
    });

    this.editor.onDidBlurEditorText(() => {
      this.onTouched();
    });
  }

  onResized(event) {
    if (this.editor) {
      this.editor.layout({
        width: event.newWidth,
        height: event.newHeight
      });
    }
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.dispose();
    }
  }
}

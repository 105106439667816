import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { MatTreeFlattener} from '@angular/material/tree';

import { Range } from 'monaco-editor';
import { MonacoEditorComponent } from './monaco-editor/monaco-editor.component';

const PEN_EXAMPLE = `///////////////////////////////////////////////////////////////////////////////
var thePen = Graphics.getPen();
///////////////////////////////////////////////////////////////////////////////

thePen.show();

var LINE_COLOR = { r: 255, g: 0, b: 0 }; // 0~255
var position = { x: -3, y: -3, z: 0 };

thePen.newSession(position, LINE_COLOR);
position.y = 3;
thePen.drawTo(position);
Cem.pause('The first session done, push the play button to continue');

position.x = 3;
position.y = -3;
thePen.newSession(position, LINE_COLOR);
position.y = 3;
thePen.drawTo(position);
Cem.pause('The second session done, push the play button to continue');

thePen.hide();

Cem.pause('About to clear the drawings...');
thePen.clear();
`;

const TURTLE_EXAMPLE = `///////////////////////////////////////////////////////////////////////////////
var theTurtle = Graphics.getTurtle(); 
///////////////////////////////////////////////////////////////////////////////

theTurtle.show();

var position = { x: -3, y: -3, z: 0 };
var frontDirection = { x: 1, y: 0, z: 0 }; // should be a unit vector
var upDirection = { x: 0, y: 0, z: 2 }; // not a unit vector, normalize it
upDirection = Math.normalize(upDirection);

theTurtle.setFrame(position, frontDirection, upDirection);
Cem.pause('Initial position and posture, push "Play" to continue');

frontDirection.x = 0;
frontDirection.y = 1;
theTurtle.setAxes(frontDirection, upDirection);
Cem.pause('Rotated to view in the Y direction, push "Play" to continue');

var DEGREE_TO_RADIAN = Math.PI / 180;

position.x = 3;
position.y = 0;
frontDirection.x = 0;
frontDirection.y = 1;
theTurtle.setFrame(position, frontDirection, upDirection);

// From now on, every position change will leave
// a line segment between the last and current positions.
theTurtle.beginTrailing();

var CIRCLE_RADIUS = 3;

// Computing power of the shell virtual machine. 1~10000, default: 300
Cem.setMachineSpeed(50);

Cem.pause('Starting to draw a circle, push "Play" to continue');
for (var i = 1; i <= 360; ++i) {
  // x(t) = r*cos(t)
  // y(t) = r*sin(t)

  var t = DEGREE_TO_RADIAN * i;
  position.x = CIRCLE_RADIUS * Math.cos(t);
  position.y = CIRCLE_RADIUS * Math.sin(t);

  // Parametric derivatives
  var dxdt = -Math.sin(t);
  var dydt = Math.cos(t);

  // The tangent direction
  frontDirection.x = dxdt;
  frontDirection.y = dydt;

  theTurtle.setFrame(position, frontDirection, upDirection);
}
theTurtle.endTrailing();

theTurtle.hide();

Cem.pause('About to clear the drawings...');
theTurtle.clear();
`;

const UTILITY_EXAMPLE = `///////////////////////////////////////////////////////////////////////////////
var theUtility = Graphics.getUtility();
///////////////////////////////////////////////////////////////////////////////

// r: 0~255, g: 0~255, b: 0~255, a: 0~1
var SPHERE_COLOR = { r: 0, g: 0, b: 255, a: 1 };

var INITIAL_HEIGHT = 5;
var position = { x: -3, y: -3, z: INITIAL_HEIGHT }; // initial position
theUtility.createSphereMarkers(1);
theUtility.configSphereMarker(0, position, SPHERE_COLOR);

var VECTOR_COLOR = { r: 255, g: 0, b: 0, a: 1 };

var GRAVITATIONAL_ACCELERATION = 9.8;

// The initial velocity.
// Should be zero but 0.1 for display purpose.
var velocity = { x: 0, y: 0, z: -0.1 };

theUtility.createVectors(1);
theUtility.configVector(0, position, velocity, VECTOR_COLOR);

// Computing power of the shell virtual machine. 1~10000, default: 300
Cem.setMachineSpeed(10000); // maximum machine speed

Cem.pause('About to start a free fall simulation');
var startTime = Cem.now(); // in seconds
var SCALE_DOWN_FACTOR = 0.5; // scale down for display purpose
while (true) {
  var t = SCALE_DOWN_FACTOR * (Cem.now() - startTime);

  // v(t) = v₀ + g*t, v₀ = 0
  // ∫v(t)dt = 0.5*g*t²

  position.z = INITIAL_HEIGHT - (0.5 * GRAVITATIONAL_ACCELERATION * t * t);
  theUtility.configSphereMarker(0, position, SPHERE_COLOR);

  velocity.z = SCALE_DOWN_FACTOR * (-t * GRAVITATIONAL_ACCELERATION);
  theUtility.configVector(0, position, velocity, VECTOR_COLOR);

  if (position.z <= 0) {
    break;
  }
}

Cem.pause('About to clear the objects...');
theUtility.clearSphereMarkers();
theUtility.clearVectors();
`;

const CANVAS_EXAMPLE = `///////////////////////////////////////////////////////////////////////////////
var theCanvas = Graphics.getCanvas(); 
///////////////////////////////////////////////////////////////////////////////

// r: 0~255, g: 0~255, b: 0~255, a: 0~1
var LINE_COLOR = { r: 255, g: 0, b: 0, a: 1 };

Cem.pause('Drawing a line on the canvas');
var lineGeometry = {
  type: Graphics.GEOM_LINE,
  start: { x: -3, y: - 3 },
  end: { x: -3, y: 3 }
};
theCanvas.draw(lineGeometry, LINE_COLOR);

Cem.pause('Drawing a circle');
var circleGeometry1 = {
  type: Graphics.GEOM_CIRCLE,
  center: { x: -1, y: 0 },
  radius: 2
};
theCanvas.draw(circleGeometry1, LINE_COLOR);

Cem.pause('Drawing another circle');
var circleGeometry2 = {
  type: Graphics.GEOM_CIRCLE,
  center: { x: 1, y: 0 },
  radius: 2
};
theCanvas.draw(circleGeometry2, LINE_COLOR);

var FILL_COLOR = { r: 0, g: 0, b: 255, a: 0.5 };
Cem.pause("Let's fill the intersecion of the two circles");

// Any point inside the region of interest is OK.
var regionSelector = { x: 0, y: 0 };

var geometries = [circleGeometry1, circleGeometry2];
theCanvas.fillComposite(geometries, FILL_COLOR, regionSelector);

Cem.pause('About to clear the drawings...');
theCanvas.clear();
`;

const INTEGRATED_EXAMPLE = `///////////////////////////////////////////////////////////////////////////////
var thePen = Graphics.getPen();
var theUtility = Graphics.getUtility();
///////////////////////////////////////////////////////////////////////////////

// Locomotive signal
// x: real part, y: imaginary part
var signal = Cem.getLocomotiveData();

// Complex Discrete Fourier Transform
var F = Math.dft(signal);
var periodDivision = (2 * Math.PI) / F.length;

function dsInitiator() {
  thePen.newSession(position, { r: 0, g: 0, b: 0, a: 1 });
  thePen.show();
  drawSignal = dsExtender; // chaining to dsExtender
}
function dsExtender() {
  thePen.drawTo(position);
}
var drawSignal = dsInitiator; // signal drawing function

// Computing power of the shell virtual machine. 1~10000, default: 300
Cem.setMachineSpeed(10000); // the maximum

theUtility.createCircles(F.length);

// r: 0~255, g: 0~255, b: 0~255, a: 0~1
var CIRCLE_COLOR = { r: 0, g: 0, b: 255, a: 0.5 };

var t, radius, phi;
var position = { z: 0 };

for (var k = 0; k <= F.length; ++k) {
  position.x = 0;
  position.y = 0;
  t = k * periodDivision;
  
  for (var i = 0; i < F.length; ++i) {
    radius = F[i].amp;
    theUtility.configCircle(i, position, radius, CIRCLE_COLOR);

    phi = t * F[i].freq + F[i].phase;
    position.x += radius * Math.cos(phi);
    position.y += radius * Math.sin(phi);
  }

  drawSignal();
}

thePen.hide();
theUtility.clearCircles();

Cem.pause('About to clear the drawings...');
thePen.clear();
`;

const EXAMPLE_CODES = [
  PEN_EXAMPLE,
  TURTLE_EXAMPLE,
  UTILITY_EXAMPLE,
  CANVAS_EXAMPLE,
  INTEGRATED_EXAMPLE
];

interface ContentTableDataNode {
  id: number;
  name: string;
  children?: ContentTableDataNode[];
}

const CONTENT_TABLE_DATA: ContentTableDataNode[] = [
  {
    id: 1,
    name: 'Introduction'
  },
  {
    id: 2,
    name: 'API Reference',
    children: [
      {
        id: 3,
        name: '(1) Math Constants and Functions'
      },
      {
        id: 4,
        name: '(2) Cem Constants and Functions'
      },
      {
        id: 5,
        name: '(3) Algebra Constants and Functions'
      },
      {
        id: 6,
        name: '(4) Graphics Constants and Functions'
      },
      {
        id: 7,
        name: '(5) Pen Graphics'
      },
      {
        id: 8,
        name: '(6) Turtle Graphics'
      },
      {
        id: 9,
        name: '(7) Utility Graphics'
      },
      {
        id: 10,
        name: '(8) Canvas Graphics'
      }
    ]
  },
  {
    id: 11,
    name: 'Examples'
  }
];

interface ContentTableNode {
  id: number;
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'mfo-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements AfterViewInit {
  private transformer = (node: ContentTableDataNode, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  public treeControl = new FlatTreeControl<ContentTableNode>(
    node => node.level, node => node.expandable
  );

  private treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  public hasChild = (_: number, node: ContentTableNode) => node.expandable;

  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public constructor(
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconInNamespace(
      'shell',
      'copy',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/shell/copy-24px.svg')
    );

    this.dataSource.data = CONTENT_TABLE_DATA;
  }
  
  public ngAfterViewInit(): void {
    this.editors.push(this.editor1);
    this.editors.push(this.editor2);
    this.editors.push(this.editor3);
    this.editors.push(this.editor4);
    this.editors.push(this.editor5);

    let index: number = 0;
    for (let editor of this.editors) {
      editor.registerOnValidatorChange(this.onValidatorChange.bind(this));
      editor.registerOnTouched(this.onTouched.bind(this));
      editor.writeValue(EXAMPLE_CODES[index++]);
    }

    this.treeControl.expandAll();
  }

  public onNodeClick(node: ContentTableNode): void {
    let id = 'mfo-s:' + node.id;
    document.getElementById(id).scrollIntoView();
  }

  public onLinkClick(n: number): void {
    let id = 'mfo-s:' + n;
    document.getElementById(id).scrollIntoView();
  }

  public onCopy(edit: number): void {
    let index = edit - 1;
    let numLines = this.editors[index].editor.getModel().getLineCount();
    let range = new Range(0, 0, numLines, 0);
    this.editors[index].editor.setSelection(range);
    this.editors[index].editor.trigger(null, 'editor.action.clipboardCopyAction', null);
  }

  public editorOptions = {
    theme: 'vs',
    language: 'javascript',
    tabSize: 2,
    fontFamily: 'D2Coding',
    fontSize: 14,
    minimap: { enabled: false },
    lineNumbersMinChars: 4,
    readOnly: true
  };

  private onValidatorChange(): void { /* monaco-related */ }
  private onTouched(): void { /* monaco-related */ }

  @ViewChild('editor1', { read: MonacoEditorComponent })
  private editor1: MonacoEditorComponent;
  @ViewChild('editor2', { read: MonacoEditorComponent })
  private editor2: MonacoEditorComponent;
  @ViewChild('editor3', { read: MonacoEditorComponent })
  private editor3: MonacoEditorComponent;
  @ViewChild('editor4', { read: MonacoEditorComponent })
  private editor4: MonacoEditorComponent;
  @ViewChild('editor5', { read: MonacoEditorComponent })
  private editor5: MonacoEditorComponent;

  private editors = new Array<MonacoEditorComponent>();
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  MATH2D_URL: 'https://www.mathfreeon.com/math2d/',
  MATH3D_URL: 'https://www.mathfreeon.com/math3d/',
  GENICUBE_URL: 'https://www.mathfreeon.com/genicube/',
  GENIMATH_URL: 'https://genimath.mathfreeon.com/',
  AI_STUDIO_URL: 'https://www.mathfreeon.com/ai-studio/',
  SIMTOOL_URL: 'https://www.mathfreeon.com/simtool/',
  GENIAI_URL: 'https://www.mathfreeon.com/geniai/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';

import { MonacoEditorModule } from './monaco-editor/monaco-editor.module';
import { MathQuillModule } from './math-quill/math-quill.module';

import { MainComponent } from './main.component';
import { AlgebraComponent } from './algebra.component';
import { ShellComponent } from './shell.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    MainComponent,
    AlgebraComponent,
    ShellComponent,
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatTreeModule,
    AppRoutingModule,
    MonacoEditorModule,
    MathQuillModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';

import { MainComponent } from './main.component';
import { AlgebraComponent } from './algebra.component';
import { ShellComponent } from './shell.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: 'algebra',
    component: AlgebraComponent,
  },
  {
    path: 'shell',
    component: ShellComponent
  },
  {
    path: '',
    redirectTo: '/main',
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

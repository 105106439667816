import { NgModule } from '@angular/core';

import { MonacoEditorLoaderDirective } from './monaco-editor-loader.directive';
import { MonacoEditorComponent } from './monaco-editor.component';
import { ResizedDirective } from './resized-event.directive';
import { MonacoDiffEditorComponent } from './monaco-diff-editor.component';

@NgModule({
  imports: [],
  declarations: [
    MonacoEditorLoaderDirective,
    MonacoEditorComponent,
    ResizedDirective,
    MonacoDiffEditorComponent
  ],
  exports: [
    MonacoEditorLoaderDirective,
    MonacoEditorComponent,
    MonacoDiffEditorComponent,
    ResizedDirective
  ]
})
export class MonacoEditorModule { }
